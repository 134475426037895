import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {CommonUtilitiesService} from "./common-utilities.service";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class TimeEntryService {

  constructor(
      private _httpClient: HttpClient,

      private _commonUtilitiesService: CommonUtilitiesService,
  ) { }

    getTimeEntry(params: object){
        return this._httpClient.get<any>(
            `${environment.apiUrl}/time-entries/${this._commonUtilitiesService.generateURLParameters(params)}`
        );
    }

    getTimeEntryRange(start: string, end: string, userId?: string){
      const params = {
          start,
          end,
          userId: userId || '',
      }
        return this._httpClient.get<any>(
            `${environment.apiUrl}/time-entries/report/${this._commonUtilitiesService.generateURLParameters(params)}`
        );
    }
    getHoursToDate(user: string, start: string, end: string){
        const params = {
            user,
            start,
            end,
        }
        return this._httpClient.get<any>(
            `${environment.apiUrl}/time-entries/hours-to-date/${this._commonUtilitiesService.generateURLParameters(params)}`
        );
    }

    updateBlockMonth(blockMonth: string){
        const params = {
            blockMonth
        }
        return this._httpClient.post<any>(`${environment.apiUrl}/time-entries/block-month`, params);
    }

    getBlockMonth(){
        return this._httpClient.get<any>(`${environment.apiUrl}/time-entries/block-month`);
    }

    sync(startDate, endDate, userId?){
      const params = {
          startDate,
          endDate,
          userId: userId || '',
      }
      return this._httpClient.post<any>(`${environment.apiUrl}/time-entries/sync_timelogs`, params);
    }
}
